import { ref, computed, watch } from '@vue/composition-api';

export function useRotatonButton(planIds, plans, rotationInDeg, setRotation) {
  const planIndex = ref(-1);
  const isRotating = ref(false);

  const icon = computed(() => {
    return planIndex.value === -1 ? 'mdi-navigation' : 'mdi-screen-rotation';
  });

  const isMapRotation = computed(() => {
    return planIndex.value === -1;
  });

  const plansWithDifferentRotations = computed(() => {
    const rotations = [];
    return plans.value.filter(plan => {
      const res =
        !rotations.includes(plan.positionRotation) &&
        planIds.value.includes(plan.id);
      if (res) {
        rotations.push(plan.positionRotation);
      }
      return res;
    });
  });

  const currentRotation = computed(
    () => plansWithDifferentRotations.value[planIndex.value]?.positionRotation
  );

  watch(
    () => rotationInDeg.value,
    () => {
      // checking that 'rotate' event wasn't called by setRotation method
      if (planIndex.value !== -1 && !isRotating.value) {
        planIndex.value = -1;
      }
    }
  );

  const rotate = async value => {
    isRotating.value = true;
    await setRotation(value);
    isRotating.value = false;
  };

  const handleClick = async () => {
    if (planIndex.value >= plansWithDifferentRotations.value.length - 1) {
      await rotate(0);
      planIndex.value = -1;
    } else {
      planIndex.value += 1;
      if (currentRotation.value !== undefined) {
        await rotate(-currentRotation.value);
      }
    }
  };

  return {
    icon,
    handleClick,
    isMapRotation
  };
}
