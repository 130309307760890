<template>
  <div class="layout-map">
    <div class="map-container" :class="{ mobile: $vuetify.breakpoint.mobile }">
      <map-component />
    </div>
    <v-sheet
      v-if="isReady && isAppDataLoaded"
      class="left-panel"
      :class="sidePanelClasses"
      elevation="4"
      tile
    >
      <router-view />
    </v-sheet>
  </div>
</template>

<script>
import MapComponent from './map';
import { useMap } from '@/compositions/map';
import { useAuth } from '@/modules/auth/api';
import { computed } from '@vue/composition-api';
import { useToggleView } from '@/layouts/map/footerMenu/useToggleView';
export default {
  name: 'LayoutMap',
  components: {
    MapComponent
  },
  setup(props, { root }) {
    const { isReady } = useMap();
    const { isAppDataLoaded } = useAuth();
    const { isMapState } = useToggleView(root.$vuetify);

    const sidePanelClasses = computed(() => {
      return {
        'w-full': root.$vuetify.breakpoint.mobile,
        compact: root.$vuetify.breakpoint.mobile && isMapState.value
      };
    });
    return { isReady, isAppDataLoaded, sidePanelClasses };
  }
};
</script>

<style lang="sass" scoped>
.layout-map
    .left-panel
        display: flex
        flex-shrink: 0
        position: absolute
        left: 0
        top: 0
        bottom: 0
        width: 412px
        max-width: 100%
        height: 100%
        z-index: 1

        &.compact
          height: fit-content
    .map-container
        position: absolute
        left: 412px
        right: 0
        top: 0
        bottom: 0

        &.mobile
          left: 0
          width: 100%
          height: 100%
    .w-full
        width: 100% !important
</style>
