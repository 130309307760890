<template>
  <v-btn id="account-btn" v-testid="'account-btn'" fab small color="primary" v-on="$listeners">
    {{ firstLetters }}
  </v-btn>
</template>

<script>
import { useAuth } from '@/modules/auth/api';
export default {
  name: 'AvatarButton',
  setup() {
    const { firstLetters } = useAuth();
    return {
      firstLetters
    };
  }
};
</script>

<style></style>
