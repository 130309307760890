<template>
  <v-list dense color="transparent" class="bottom-toolbar">
    <v-list-item class="d-flex justify-center mb-3">
      <v-btn fab small color="white" @click="handleClick">
        <v-icon
          :style="{
            transform: isMapRotation
              ? `rotate(${rotationInDeg}deg)`
              : undefined,
            transition: `none`
          }"
        >
          {{ icon }}
        </v-icon>
      </v-btn>
    </v-list-item>
    <v-list-item class="d-flex justify-center mb-3">
      <map-layers-control
        :base-layers="baseLayers"
        :plans="plans"
        :geo-types="geoTypes"
        :selected-base-layer-id.sync="selectedBaseLayerId"
        :selected-plan-ids.sync="showedPlanIds"
        @update:selected-base-layer-id="selectBaseLayer"
        @onGeoTypeSelect="selectGeoType"
      />
    </v-list-item>
    <v-list-item class="d-flex justify-center mb-3">
      <geotags-control />
    </v-list-item>
    <v-list-item class="d-flex justify-center mb-3">
      <v-btn fab small color="white" @click="mapZoomIn">
        <v-icon>$add</v-icon>
      </v-btn>
    </v-list-item>
    <v-list-item class="d-flex justify-center mb-3">
      <v-btn fab small color="white" @click="mapZoomOut">
        <v-icon>$minus</v-icon>
      </v-btn>
    </v-list-item>
    <v-list-item>
      <view-toggle-button
        v-if="$vuetify.breakpoint.mobile"
        class="view-toggle-btn"
      />
      <v-btn fab medium color="primary" @click="gotoHome">
        <v-icon>$home</v-icon>
      </v-btn>
    </v-list-item>
  </v-list>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api';
import MapLayersControl from './MapLayersControl';
import { useMap } from '@/compositions/map';
import { baseLayersDefinition } from '@/compositions/map/base-layer';
import { usePlans } from '@/modules/plans/compositions/plans';
import GeotagsControl from './GeotagsControl';
import { useRotatonButton } from './rotationButton';
import useGeneralSettings from '@/modules/settings/general/useGeneralSettings';
import ViewToggleButton from '@/layouts/map/footerMenu/ViewToggleButton.vue';

export default {
  name: 'FooterMenu',
  components: {
    ViewToggleButton,
    MapLayersControl,
    GeotagsControl
  },
  setup(_, { root }) {
    const {
      mapZoomIn,
      mapZoomOut,
      rotationInDeg,
      setRotation,
      setDefaultCenter,
      setDefaultZoom,
      setDefaultRotation,
      selectBaseLayer,
      selectedBaseLayerId,
      plans: { showedPlanIds },
      geozones: {
        toggleVisibleGeozoneLayer,
        visibleGeozoneLayer,
        setVisibleGeozoneLayer
      },
      landmarks: {
        toggleVisibleLandmarkLayer,
        visibleLandmarkLayer,
        setVisibleLandmarkLayer
      }
    } = useMap();

    const { getById } = useGeneralSettings();

    const disabledLandmarkType = ref(false);
    const disabledGeozoneType = ref(false);

    const geoTypes = computed(() => {
      return [
        {
          id: 'landmarks',
          name: 'Landmarks',
          checked: visibleLandmarkLayer.value || !!disabledLandmarkType.value,
          disabled: disabledLandmarkType.value
        },
        {
          id: 'geozones',
          name: 'Geozones',
          checked: visibleGeozoneLayer.value || disabledGeozoneType.value,
          disabled: disabledGeozoneType.value
        }
      ];
    });

    const baseLayers = computed(() =>
      baseLayersDefinition.filter(
        layer =>
          !!getById('programAvailableMaps').defaultValue[layer.id] ||
          layer.id === 'empty'
      )
    );

    const gotoHome = () => {
      setDefaultCenter();
      setDefaultZoom();
      setDefaultRotation();
    };

    const { enabledPlans: plans } = usePlans();

    const { icon, handleClick, isMapRotation } = useRotatonButton(
      showedPlanIds,
      plans,
      rotationInDeg,
      setRotation
    );

    const selectGeoType = id => {
      if (id === 'landmarks') {
        toggleVisibleLandmarkLayer();
      } else {
        toggleVisibleGeozoneLayer();
      }
    };

    watch(
      () => root.$route,
      () => {
        disabledLandmarkType.value = false;
        disabledGeozoneType.value = false;
        if (
          root.$route.matched.some(
            matchedRoute => matchedRoute.name === 'landmarks'
          )
        ) {
          setVisibleLandmarkLayer(true);
          disabledLandmarkType.value = true;
        }
        if (
          root.$route.matched.some(
            matchedRoute => matchedRoute.name === 'geozones'
          )
        ) {
          setVisibleGeozoneLayer(true);
          disabledGeozoneType.value = true;
        }
      },
      {
        immediate: true
      }
    );

    return {
      mapZoomIn,
      mapZoomOut,
      rotationInDeg,
      setRotation,
      gotoHome,
      baseLayers,
      plans,
      selectedBaseLayerId,
      showedPlanIds,
      selectBaseLayer,
      icon,
      handleClick,
      isMapRotation,
      geoTypes,
      selectGeoType
    };
  }
};
</script>
<style lang="sass" scoped>
.view-toggle-btn
    position: absolute
    left: -56px
.bottom-toolbar
    position: absolute
    right: 0
    bottom: 0
::v-deep
  .theme--light.v-btn:focus::before
    opacity: 0
</style>
