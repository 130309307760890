<template>
  <v-menu
    v-model="showPopup"
    offset-y
    :nudge-bottom="12"
    :nudge-left="360"
    max-width="412"
  >
    <template #activator="{}">
      <v-btn
        fab
        small
        rounded
        color="white"
        class="mr-3"
        :loading="loading"
        @click="handleShowApps"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>
    <v-card class="apps">
      <button
        v-for="item in appList"
        :key="item.id"
        class="text-decoration-none apps__item"
        :class="{ 'apps__item--disable': !item.enabled }"
        @click="goToApp(item)"
      >
        <img
          class="apps__item-image"
          :src="`/img/${item.icon}`"
          :alt="item.name"
        />
        <span class="apps__item-text pt-2">{{ item.name }}</span>
      </button>
    </v-card>
  </v-menu>
</template>

<script>
import { ref } from '@vue/composition-api';

import { useApp } from '@/modules/account/useApp';

export default {
  name: 'AppSelect',
  setup() {
    const showPopup = ref(false);
    const { appList, onResult, goToApp, loading, loadApp } = useApp();

    onResult(() => {
      showPopup.value = true;
    });

    const handleShowApps = () => {
      if (showPopup.value) {
        showPopup.value = false;
        return;
      }
      loadApp();
    };

    return {
      showPopup,
      appList,
      loading,
      goToApp,
      handleShowApps
    };
  }
};
</script>

<style lang="scss">
.apps {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  padding: 25px;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item--disable {
    pointer-events: none;
    filter: grayscale(100);
    opacity: 0.5;
  }

  &__item-image {
    max-width: 40px;
  }

  &__item-text {
    color: #000;
  }
}

.theme--dark .apps__item-text {
  color: #fff;
}
</style>
