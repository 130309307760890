<template>
  <v-menu v-if="object" v-bind="$attrs" v-on="$listeners">
    <v-card max-height="312" width="342">
      <v-img v-if="!!pictureUrl" height="240" :src="pictureUrl" />
      <v-list-item class="header">
        <v-list-item-content>
          <v-list-item-title class="text-h6 font-weight-medium">
            {{ object.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <div class="d-flex align-center">
              <div class="mr-2" :class="{ 'red--text': !object.enabled }">
                {{ object.enabled ? 'Enabled' : 'Disabled' }}
              </div>
              <div class="mr-2 d-flex flex-nowrap">
                <v-icon
                  v-if="batteryIcon"
                  small
                  :color="batteryIcon.color"
                  v-text="batteryIcon.icon"
                />
                <div
                  v-if="showBatteryLevel"
                  :style="`color: ${batteryIcon.color}`"
                >
                  {{ object.statusesBatteryLevel + '%' }}
                </div>
              </div>
              <v-icon
                v-if="activityIcon"
                :color="activityIcon.color"
                class="mr-2"
                small
                v-text="activityIcon.icon"
              />
              <v-icon
                v-if="alarmIcon"
                :color="alarmIcon.color"
                class="mr-2"
                small
                v-text="alarmIcon.icon"
              />
              <v-icon
                v-if="dataIcon"
                :color="dataIcon.color"
                class="mr-2"
                small
                v-text="dataIcon.icon"
              />
              <v-icon
                v-if="gpsIcon"
                :color="gpsIcon.color"
                class="mr-2"
                small
                v-text="gpsIcon.icon"
              />
              <v-icon
                v-if="object.statusesEmulation"
                class="mr-2"
                small
                v-text="'$emulation'"
              />
              <v-icon v-if="object.muted" class="mr-2" small v-text="'$mute'" />
              <v-icon
                v-if="object.favourite"
                class="mr-2"
                small
                v-text="'$star_on'"
              />
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-menu>
</template>

<script>
import { useObjects } from '@/modules/objects/compositions/objects';
import { computed } from '@vue/composition-api';
import { useObjectStatus } from '@/modules/objects/compositions/objectStatus';
import { mediaClient } from '@/provider';

export default {
  name: 'ObjectPopup',
  props: {
    objectId: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { listMap } = useObjects();

    const object = computed(() => listMap.value[props.objectId]);
    const objectStatus = useObjectStatus(object);

    return {
      object,
      ...objectStatus,
      pictureUrl: computed(() =>
        mediaClient.getImageUrl(object.value?.infoImage)
      )
    };
  }
};
</script>

<style lang="sass" scoped>
.header
  height: 72px
</style>
