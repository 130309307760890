<template>
  <div v-if="isAnimatingMode" class="track-animation-control">
    <v-btn fab medium color="primary" @click="handlePlayButton">
      <v-icon>{{ isPlaying ? '$pause' : '$play' }}</v-icon>
    </v-btn>
    <v-btn fab small color="primary" @click="handleBackward">
      <v-icon>$skip_backward</v-icon>
    </v-btn>
    <v-btn fab small color="primary" @click="handleStopButton">
      <v-icon>$stop</v-icon>
    </v-btn>
    <v-btn fab small color="primary" @click="handleForward">
      <v-icon>$skip_forward</v-icon>
    </v-btn>
    <v-slider
      v-model="speed"
      class="slider"
      min="1"
      max="2000"
      step="10"
      track-color="grey"
      hide-details
    />
  </div>
</template>

<script>
import { useMap } from '@/compositions/map';
export default {
  name: 'TrackAnimationControl',
  components: {},
  setup() {
    const {
      track: {
        startPlayTrack,
        pausePlayTrack,
        stopPlayTrack,
        nextTrackLine,
        prevTrackLine,
        isPlaying,
        isAnimatingMode,
        speed
      }
    } = useMap();

    const handlePlayButton = () => {
      if (!isPlaying.value) {
        startPlayTrack();
      } else {
        pausePlayTrack();
      }
    };

    const handleStopButton = () => {
      stopPlayTrack();
    };

    const handleForward = () => {
      nextTrackLine();
    };

    const handleBackward = () => {
      prevTrackLine();
    };

    return {
      handlePlayButton,
      handleStopButton,
      handleBackward,
      handleForward,
      isPlaying,
      isAnimatingMode,
      speed
    };
  }
};
</script>

<style lang="sass" scoped>


.track-animation-control
  position: absolute
  display: flex
  gap: 16px
  align-items: center
  bottom: 8px
  left: 15px


.slider
  width: 204px

@media(max-width: 960px)
  .track-animation-control
    width: 224px
    flex-wrap: wrap
  .slider
    order: -1

::v-deep
  .theme--light.v-btn:focus::before
    opacity: 0
</style>
