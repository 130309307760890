<template>
  <v-sheet>
    <account-menu-header />
    <v-divider />
    <account-menu-list />
  </v-sheet>
</template>

<script>
import AccountMenuHeader from './AccountMenuHeader';
import AccountMenuList from './AccountMenuList';

export default {
  name: 'AccountMenu',
  components: {
    AccountMenuHeader,
    AccountMenuList
  }
};
</script>

<style></style>
