<template>
  <v-menu
    :nudge-left="60"
    :max-height="300"
    left
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab small color="white" v-bind="attrs" v-on="on">
        <v-icon>
          $layers
        </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        v-for="plan in plans"
        :key="plan.id"
        @click="$emit('update:selected-plan-ids', getSelectedPlanIds(plan.id))"
      >
        <v-list-item-icon>
          <v-icon
            :color="(selectedPlanIds.includes(plan.id) && 'primary') || ''"
            v-text="
              selectedPlanIds.includes(plan.id)
                ? '$checkbox_checked'
                : '$checkbox_unchecked'
            "
          />
        </v-list-item-icon>
        <v-list-item-content v-text="plan.name" />
      </v-list-item>
      <v-divider v-if="plans.length" />
      <v-list-item
        v-for="geoType in geoTypes"
        :key="geoType.id"
        @click="!geoType.disabled && $emit('onGeoTypeSelect', geoType.id)"
      >
        <v-list-item-icon>
          <v-icon
            :disabled="geoType.disabled"
            :color="geoType.checked ? 'primary' : ''"
            v-text="
              geoType.checked ? '$checkbox_checked' : '$checkbox_unchecked'
            "
          />
        </v-list-item-icon>
        <v-list-item-content v-text="geoType.name" />
      </v-list-item>
      <v-divider />
      <v-list-item
        v-for="layer in baseLayers"
        :key="layer.id"
        @click="$emit('update:selected-base-layer-id', layer.id)"
      >
        <v-list-item-icon>
          <v-icon
            :color="(layer.id === selectedBaseLayerId && 'primary') || ''"
            v-text="
              layer.id === selectedBaseLayerId
                ? '$radio_checked'
                : '$radio_unchecked'
            "
          />
        </v-list-item-icon>
        <v-list-item-content v-text="layer.name" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'MapLayersControl',
  props: {
    baseLayers: {
      type: Array,
      default: () => []
    },
    plans: {
      type: Array,
      default: () => []
    },
    geoTypes: {
      type: Array,
      default: () => []
    },
    selectedBaseLayerId: {
      type: [String, Number],
      default: ''
    },
    selectedPlanIds: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const getSelectedPlanIds = id => {
      const { selectedPlanIds } = props;
      const ids = selectedPlanIds.slice(0);
      const index = ids.findIndex(i => i === id);
      if (index > -1) {
        ids.splice(index, 1);
        return ids;
      } else {
        return [...ids, id];
      }
    };
    return {
      getSelectedPlanIds
    };
  }
};
</script>

<style></style>
