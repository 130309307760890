<template>
  <div v-if="!$vuetify.breakpoint.mobile" class="right-top-toolbar mr-5 mt-3">
    <notifications />
    <app-select />
    <v-menu offset-y nudge-width="300" :nudge-bottom="12">
      <template v-slot:activator="{ on }">
        <account-button v-on="on" />
      </template>
      <account-menu />
    </v-menu>
  </div>
</template>

<script>
import AccountMenu from './AccountMenu';
import AccountButton from './AccountButton';
import Notifications from '@/layouts/map/headerMenu/notifications/index';
import AppSelect from '@/layouts/map/headerMenu/AppSelect';
export default {
  name: 'AppRightTopToolbar',
  components: {
    AppSelect,
    Notifications,
    AccountMenu,
    AccountButton
  }
};
</script>

<style lang="sass" scoped>
.right-top-toolbar
    position: absolute
    right: 0
    top: 0
</style>
